import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

const ClientAreaOne = () => {
  return (
    <div
      className="client-bg-area"
      style={{ backgroundImage: "url(assets/img/bg/client-bg1.webp)" }}
    >
      <div className="client-area-1 text-center">
        <div className="container">
          <div className="row global-carousel">
            <Swiper
              spaceBetween={20}
              slidesPerGroup={2}
              speed={1000}
              autoplay={{ delay: 3000 }}
              className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                500: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
                1400: {
                  slidesPerView: 5,
                },
              }}
            >
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Chevrolet.png" alt="Autopartes para Chevrolet" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Nissan.png" alt="Autopartes para Nissan" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Volkswagen.png" alt="Autopartes para Volkswagen" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Ford.png" alt="Autopartes para Ford" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Honda.png" alt="Autopartes para Honda" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Toyota.png" alt="Autopartes para Toyota" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Renault.png" alt="Autopartes para Renault" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Subaru.png" alt="Autopartes para Subaru" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Mazda.png" alt="Autopartes para Mazda" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-auto">
                  <div className="client-logo">
                    <Link to="/">
                      <img src="assets/img/client/Suzuki.png" alt="Autopartes para Susuki" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {/*==============================
    Appointment Area  
    ==============================*/}
      <div className="appointment-area-1 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="appointment-form-wrap bg-theme">
                <div className="title-area">
                  <span className="sub-title text-white">Cotiza Pernos para Puerta - RALTE</span>
                  <h2 className="sec-title text-white">
                  Cotiza Fácil y Rápido
                  </h2>
                </div>
                <form
                  action="mail.php"
                  method="POST"
                  className="appointment-form ajax-contact"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control style-border2"
                          name="name"
                          id="name"
                          placeholder="Nombre"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control style-border2"
                          name="email"
                          id="email"
                          placeholder="Correo Electrónico"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control style-border2"
                          name="number"
                          id="number"
                          placeholder="Celular"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <select
                          name="subject"
                          id="subject"
                          className="form-select style-border2"
                          defaultValue={"Choose"}
                        >
                          <option value="Choose">Estado</option>
                          <option value="Agusacalientes">Aguascalientes</option>
                          <option value="Baja California">Baja California</option>
                          <option value="Baja California Sur">Baja California Sur</option>
                          <option value="Campeche">Campeche</option>
                          <option value="Chiapas">Chiapas</option>
                          <option value="Chihuahua">Chihuahua</option>
                          <option value="Colima">Colima</option>
                          <option value="Ciudad de México">Ciudad de México</option>
                          <option value="Durango">Durango</option>
                          <option value="Guanajuato">Guanajuato</option>
                          <option value="Guerrero">Guerrero</option>
                          <option value="Hidalgo">Hidalgo</option>
                          <option value="Jalisco">Jalisco</option>
                          <option value="Mpexico">México</option>
                          <option value="Michoacán">Michoacán</option>
                          <option value="Nayarit">Nayarit</option>
                          <option value="Nuevo León">Nuevo León</option>
                          <option value="Oaxaca">Oaxaca</option>
                          <option value="Puebla">Puebla</option>
                          <option value="Querétaro">Querétaro</option>
                          <option value="Quintana Roo">Quintana Roo</option>
                          <option value="San Luis Potosí">San Luis Potosí</option>
                          <option value="Sinaloa">Sinaloa</option>
                          <option value="Sonora">Sonora</option>
                          <option value="Tabasco">Tabasco</option>
                          <option value="Tamaulipas">Tamaulipas</option>
                          <option value="Tlaxcala">Tlaxcala</option>
                          <option value="Veracruz">Veracruz</option>
                          <option value="Yucatán">Yucatán</option>
                          <option value="Zacatecas">Zacatecas</option>

                        </select>
                        <i className="fas fa-angle-down text-white" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <select
                          name="subject"
                          id="subject"
                          className="form-select style-border2"
                          defaultValue={"Choose"}
                        >
                          <option value="Choose">Selecciona  tu perno (código)</option>
                          <option value="100-PP1 - Perno 1">100-PP1 - Perno 1</option>
                          <option value="100-PP1F - Perno 1F">100-PP1F - Perno 1F</option>
                          <option value="100-PP1FE - Perno 1FE">100-PP1FE - Perno 1FE</option>
                          <option value="100-PP1FEL - Perno 1FEL">100-PP1FEL - Perno 1FEL</option>

                          <option value="100-PP2 - Perno 2">100-PP2 - Perno 2</option>
                          <option value="100-PP2A - Perno 2A">100-PP2A - Perno 2A</option>

                          <option value="100-PP3 - Perno 3">100-PP3 - Perno 3</option>
                          <option value="100-PP3A - Perno 3A">100-PP3A - Perno 3A</option>

                          <option value="100-PP4 - Perno 4">100-PP4 - Perno 4</option>
                          <option value="100-PP5 -Perno 5">100-PP5 - Perno 5</option>
                          <option value="100-PP6 - Perno 6">100-PP6 - Perno 6</option>
                          <option value="100-PP7 - Perno 7">100-PP7 - Perno 7</option>
                          <option value="100-PP8 -Perno 8">100-PP8 - Perno 8</option>
                          <option value="100-PP8A - Perno 8A">100-PP8A - Perno 8A</option>
                          <option value="100-PP9 - Perno 9">100-PP9 - Perno 9</option>

                          <option value="100-PP10 - Perno 10">100-PP10 - Perno 10</option>
                          <option value="100-PP11 - Perno 11">100-PP11 - Perno 11</option>
                          <option value="100-PP12 - Perno 12">100-PP12 - Perno 12</option>
                          <option value="100-PP14 - Perno 14">100-PP14 - Perno 14</option>
                          <option value="100-PP15 - Perno 15">100-PP15 - Perno 15</option>
                          <option value="100-PP16 - Perno 16">100-PP16 - Perno 16</option>
                          <option value="100-PP16A - Perno 16A">100-PP16A - Perno 16A</option>
                          <option value="100-PP16B - Perno 16B">100-PP16B - Perno 16B</option>
                          <option value="100-PP16C - Perno 16C">100-PP16C - Perno 16C</option>
                          <option value="100-PP16D - Perno 16D">100-PP16D - Perno 16D</option>
                          <option value="100-PP17 - Perno 17">100-PP17 - Perno 17</option>
                          <option value="100-PP19 - Perno 19">100-PP19 - Perno 19</option>
                          <option value="100-PP19A - Perno 19A">100-PP19A - Perno 19A</option>

                          <option value="100-PP20 - Perno 20">100-PP20 - Perno 20</option>
                          <option value="100-PP20-15 - Perno 20-15">100-PP20-15 - Perno 20-15</option>
                          <option value="100-PP21 - Perno 21">100-PP21 - Perno 21</option>
                          <option value="100-PP22 - Perno 22">100-PP22 - Perno 22</option>
                          <option value="100-PP23 - Perno 23">100-PP23 - Perno 23</option>
                          <option value="100-PP24 - Perno 24">100-PP24 - Perno 24</option>
                          <option value="100-PP25 - Perno 25">100-PP25 - Perno 25</option>
                          <option value="100-PP26 - Perno 26">100-PP26 - Perno 26</option>
                          <option value="100-PP27 - Perno 27">100-PP27 - Perno 27</option>
                          <option value="100-PP28 - Perno 28">100-PP28 - Perno 28</option>
                          <option value="100-PP29 - Perno 29">100-PP29 - Perno 29</option>

                          <option value="100-PP30 - Perno 30">100-PP30 - Perno 30</option>
                          <option value="100-PP31 - Perno 31">100-PP31 - Perno 31</option>
                          <option value="100-PP32 - Perno 32">100-PP32 - Perno 32</option>
                          <option value="100-PP33 - Perno 33">100-PP33 - Perno 33</option>
                          <option value="100-PP34 - Perno 34">100-PP34 - Perno 34</option>
                          <option value="100-PP35 - Perno 35">100-PP35 - Perno 35</option>
                          <option value="100-PP36 - Perno 36">100-PP36 - Perno 36</option>
                          <option value="100-PP37 - Perno 37">100-PP37 - Perno 37</option>
                          <option value="100-PP38 - Perno 38">100-PP38 - Perno 38</option>
                          <option value="100-PP39 - Perno 39">100-PP39 - Perno 39</option>
                          
                          <option value="100-PP41 - Perno 41">100-PP41 - Perno 41</option>
                          <option value="100-PP42 - Perno 42">100-PP42 - Perno 42</option>
                          <option value="100-PP43 - Perno 43">100-PP43 - Perno 43</option>

                          <option value="100-PP50 - Perno 50">100-PP50 - Perno 50</option>
                          <option value="100-PP51 - Perno 51">100-PP51 - Perno 51</option>
                          <option value="100-PP52 - Perno 52">100-PP52 - Perno 52</option>
                          <option value="100-PP53 - Perno 53">100-PP53 - Perno 53</option>
                          <option value="100-PP54 - Perno 54">100-PP54 - Perno 54</option>

                          <option value="100-PP61 - Perno 61">100-PP61 - Perno 61</option>
                          <option value="100-PP62 - Perno 62">100-PP62 - Perno 62</option>
                          <option value="Juego de perno silverado">Juego de perno silverado</option>
                          <option value="Muestrario de Pernos">Muestrario de Pernos</option>

                        </select>
                        <i className="fas fa-angle-down text-white" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <select
                          name="subject"
                          id="subject"
                          className="form-select style-border2"
                          defaultValue={"Choose"}
                        >
                          <option value="Choose">Cantidad</option>
                          <option value="10 piezas">10 Piezas (mínimo)</option>
                          <option value="50 Piezas">50 Piezas</option>
                          <option value="3K (envíos)">3K (envíos)</option>
                          <option value="Personalizada">Personalizada</option>

                        </select>
                        <i className="fas fa-angle-down text-white" />
                      </div>
                    </div>
                    
                  </div>
                  <div className="form-group col-12">
                    <textarea
                      placeholder="Detalla las necesidades de tu pedido tales como: acabado, ubicación de envío u otros detalles."
                      id="contactForm"
                      className="form-control style-border2"
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-btn col-12">
                    <button className="btn style3">
                      COTIZA AHORA <i className="fas fa-arrow-right ms-2" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="appointment-thumb-1">
          <img
            src="assets/img/normal/appointment-thumb-1-1.webp"
            alt="Cotiza pernos para puerta - RALTE"
          />
        </div>
      </div>
    </div>
  );
};

export default ClientAreaOne;
