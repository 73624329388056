import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import ScrollToTop from "react-scroll-to-top";
import Home from "./pages/Home";
import Pernos from "./pages/Pernos";
import Prueba from "./pages/Prueba";


function App() {
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <ScrollToTop smooth color="#0047BA" />
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/pernos-para-puerta" element={<Pernos/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
