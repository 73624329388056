import React from "react";
import { Link } from "react-router-dom";

const HeroOne = () => {
  return (
    <div
      className="hero-wrapper hero-1"
      id="hero"
      style={{ backgroundImage: "url(assets/img/hero/fabricantes-de-pernos-de-puerta-mexico-RALTE-mayoreo-y-menudeo.webp)", paddingTop:"80px" }}
    >
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-xl-6">
            <div className="hero-style1">
              <span className="sub-title text-white">Fabricación y Venta Especializada para el Mercado Automotriz</span>
              <h1 className="hero-title text-white">
              Pernos para Puerta de Alta{" "}  
                <span>
                  <img src="assets/img/hero/hero_shape_1.webp" alt="RALTE" />
                    Resistencia
                </span>{" "}
               
              </h1>
              <p className="hero-text text-white">
              En RALTE, somos líderes en la fabricación de pernos para puerta. Ofrecemos calidad superior, entregas rápidas y personalización según tus necesidades. Venta al mayoreo y en mostrador para refaccionarias y talleres
              </p>
              <div className="btn-group">
                <Link to="/about" className="btn ">
                  Ver Catálogo
                </Link>
                <Link to="/service" className="btn styleY">
                  Solicita Cotización
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="hero-thumb text-center">
              <img src="assets/img/hero/venta-de-pernos-de-puerta-mayoreo-y-menudeo-RALTE.webp" alt="Pernos para puerta Ralte" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroOne;
