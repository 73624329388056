import React from "react";

const FaqAreaThree = () => {
  return (
    <div className="faq-area-1 space">
      <div className="container">
        <div
          className="faq-wrap space"
          style={{
            backgroundImage: "url(assets/img/update-img/faq-bg.webp)",
            backgroundColor: "#F4F4F4",
            backgroundSize: "cover",
          }}
        >
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-11">
              <div className="title-area text-center">
                <span className="sub-title">Estamos para atenderte</span>
                <h2 className="sec-title">
                  Preguntas Frecuentes sobre Pernos para Puerta
                </h2>
              </div>
            </div>
            <div className="col-xl-8 col-md-10 col-11">
              <div className="accordion-area accordion" id="faqAccordion">
                <div className="accordion-card style3 active">
                  <div className="accordion-header" id="collapse-item-1">
                    <button
                      className="accordion-button "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-1"
                      aria-expanded="true"
                      aria-controls="collapse-1"
                    >
                      {" "}
                      ¿Cuáles son los tiempos de entrega?
                    </button>
                  </div>
                  <div
                    id="collapse-1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="collapse-item-1"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                      Para productos en inventario, el tiempo de entrega es inmediato. 
                      Si requiere fabricación, el tiempo es de 4 días hábiles.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card style3">
                  <div className="accordion-header" id="collapse-item-2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-2"
                      aria-expanded="false"
                      aria-controls="collapse-2"
                    >
                      {" "}
                      ¿Qué pasa si el perno que busco no está en la lista?
                    </button>
                  </div>
                  <div
                    id="collapse-2"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-2"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                      Escríbennos para poder asesorarte sobre el perno más adecuado a tus necesidades. 
                      Sino lo tenemos, podemos fabricarlo en un plazo de 4 días. Contáctanos con las medidas o características específicas. 
                      *La fabricación de un perno "nuevo" o fuera de nuestra lsita está sujeto a un mínimo de piezas, depende el modelo y la cantidad.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card style3">
                  <div className="accordion-header" id="collapse-item-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      {" "}
                      ¿Cuál es el mínimo para envíos fuera de la Ciudad de México?
                    </button>
                  </div>
                  <div
                    id="collapse-3"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-3"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                      Los envíos al interior del país son para pedidos mayores a 3,000 piezas.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-card style3">
                  <div className="accordion-header" id="collapse-item-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      {" "}
                      ¿Puedo comprar al menudeo?
                    </button>
                  </div>
                  <div
                    id="collapse-3"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-3"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                      Sí, las ventas al menudeo se realizan exclusivamente en nuestro mostrador en la planta de CDMX, con un mínimo de 10 piezas.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card style3">
                  <div className="accordion-header" id="collapse-item-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      {" "}
                      ¿Cómo agilizo el proceso de compra?
                    </button>
                  </div>
                  <div
                    id="collapse-3"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-3"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                      Identifica el código del perno en nuestro catálogo o proporciona las medidas específicas para procesar tu solicitud más rápido.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card style3">
                  <div className="accordion-header" id="collapse-item-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      {" "}
                      ¿Qué opciones de acabados y materiales manejan?
                    </button>
                  </div>
                  <div
                    id="collapse-3"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-3"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                      Ofrecemos acabados en acero galvanizado, zincado y otros materiales según las necesidades del cliente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAreaThree;
