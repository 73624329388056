import React from "react";
import { Link } from "react-router-dom";

const SubscribeOne = () => {
  return (
    <div className="container">
      <div
        className="footer-top-1 bg-theme"
        style={{ backgroundImage: "url(assets/img/bg/footer-top-bg1-1.webp)" }}
      >
        <div className="call-media-wrap">
          <div className="media-body">
            <h2 className="hero-title text-white">RALTE: Piezas que Impulsan <br/> la Industria Automotriz </h2>
          </div>
        </div>
        <div className="">
          <a className="btn style-border2" href="#">Contáctanos <i className="fas fa-arrow-right ms-2"></i></a>
        </div>
      </div>
    </div>
  );
};

export default SubscribeOne;
