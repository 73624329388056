import React from "react";

const WhyChooseUsOne = () => {
  return (
    <div className="space-top wcu-area-1 space-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            <div className="title-area text-center">
              <span className="sub-title">RALTE - Los de la etiqueta azul</span>
              <h2 className="sec-title">
              Todo lo que Necesitas Saber sobre Pernos para Puerta{" "}
                <img
                  className="title-bg-shape shape-center"
                  src="assets/img/bg/title-bg-shape-1.webp"
                  alt="RALTE"
                />
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gy-40 gx-60 align-items-center">
          <div className="col-xl-4 order-xl-2">
            <div className="wcu-thumb-1">
              <img src="assets/img/normal/venta-de-pernos-para-puerta-ralte-mexico.webp" alt="Fixturbo" />
            </div>
          </div>
          <div className="col-xl-4 col-md-6 order-xl-1">
            <div className="wcu-card-wrap right-wrap">
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-1.svg" alt="Fixturbo" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">¿Qué es un perno?</h4>
                  <p className="wcu-card-text">
                  Un perno es un componente utilizado para unir y asegurar puertas en vehículos.
                  Fabricados con materiales resistentes, son ideales para soportar el peso y el movimiento constante de las puertas.{" "}
                  </p>
                </div>
              </div>
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-2.svg" alt="Fixturbo" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">Somos fabricantes</h4>
                  <p className="wcu-card-text">
                  Garantizamos la calidad desde el diseño hasta la producción.{" "}
                  </p>
                </div>
              </div>
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-3.svg" alt="Fixturbo" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">Opciones de fabricación</h4>
                  <p className="wcu-card-text">
                  Creamos piezas personalizadas según tus requerimientos.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 order-xl-3">
            <div className="wcu-card">
              <div className="icon">
                <img src="assets/img/icon/wcu-icon_2-4.svg" alt="Fixturbo" />
              </div>
              <div className="wcu-card-details">
                  <h4 className="wcu-card-title">¿Para qué sirve un perno?</h4>
                  <p className="wcu-card-text">
                  Los pernos para puerta aseguran la estabilidad, permiten el correcto funcionamiento de las bisagras y contribuyen a la seguridad del vehículo.{" "}
                </p>
              </div>
            </div>
            <div className="wcu-card">
              <div className="icon">
                <img src="assets/img/icon/wcu-icon_2-5.svg" alt="Fixturbo" />
              </div>
              <div className="wcu-card-details">
                <h4 className="wcu-card-title">Entregas inmediatas</h4>
                <p className="wcu-card-text">
                Disponibilidad de inventario y tiempos de fabricación rápidos.{" "}
                </p>
              </div>
            </div>
            <div className="wcu-card">
              <div className="icon">
                <img src="assets/img/icon/wcu-icon_2-6.svg" alt="Fixturbo" />
              </div>
              <div className="wcu-card-details">
                <h4 className="wcu-card-title">Variedad</h4>
                <p className="wcu-card-text">
                Contamos con pernos para múltiples marcas y modelos de vehículos.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUsOne;
