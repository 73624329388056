import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade, Autoplay } from "swiper";
import { Link } from "react-router-dom";
const ServiceAreaThree = () => {
  return (
    <div className="service-area-2 space-bottom position-relative z-index-common">
      <div className="container">
        <div className="row gy-4 justify-content-center">
          <div className="col-xxl-5 col-xl-6">
            <div className="service-form-wrap">
              <h3>¿Qué refacción estás buscando?</h3>
              <form className="default-form-wrap">
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-group m-0">
                      <select
                        name="subject"
                        id="subject"
                        className="form-select"
                        defaultValue={"Productos"}
                      >
                        <option value="Productos">Productos</option>
                        <option value="Pernos para puerta">Pernos para puerta</option>
                        <option value="Birlos">Birlos</option>
                        <option value="Birlos Extrapesado">Birlos Extrapesado</option>
                        <option value="Tuercas RALTE">Tuercas RALTE</option>
                        <option value="Tornillo caliper">Tornillo caliper</option>
                        <option value="Tuercas BI">Tuercas BI</option>
                        <option value="Terminales">Terminales</option>
                        <option value="Rondana">Rondana</option>
                        <option value="Seguros Interior">Seguros Interior</option>
                        <option value="Seguros Exterior">Seguros Exterior</option>
                        <option value="Cuña">Cuña</option>
                        <option value="Casquillo">Casquillo</option>
                        <option value="Tapón de carter">Tapón de carter</option>
                        <option value="Abrazadera">Abrazadera</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="form-group m-0">
                    <select
                      name="subject"
                      id="subject"
                      className="form-select"
                      defaultValue={""}
                    >
                      <option value="" disabled hidden>
                        Elige el modelo
                      </option>
                      <option value="Chevrolet">Chevrolet</option>
                      <option value="Nissan">Nissan</option>
                      <option value="Volkswagen">Volkswagen</option>
                      <option value="Ford">Ford</option>
                      <option value="Dodge">Dodge</option>
                      <option value="Acura">Acura</option>
                      <option value="Alfa Romeo">Alfa Romeo</option>
                      <option value="Aston Martin">Aston Martin</option>
                      <option value="Audi">Audi</option>
                      <option value="BAIC">BAIC</option>
                      <option value="Bentley">Bentley</option>
                      <option value="BMW">BMW</option>
                      <option value="Buick">Buick</option>
                      <option value="BYD">BYD</option>
                      <option value="Cadillac">Cadillac</option>
                      <option value="Changan">Changan</option>
                      <option value="Chirey">Chirey</option>
                      <option value="Chrysler">Chrysler</option>
                      <option value="Cupra">Cupra</option>
                      <option value="Datsun">Datsun</option>
                      <option value="DFSK">DFSK</option>
                      <option value="FAW">FAW</option>
                      <option value="Ferrari">Ferrari</option>
                      <option value="Fiat">Fiat</option>
                      <option value="Foton">Foton</option>
                      <option value="Freightliner">Freightliner</option>
                      <option value="Geely">Geely</option>
                      <option value="GEO">GEO</option>
                      <option value="Giant Motors">Giant Motors</option>
                      <option value="GMC">GMC</option>
                      <option value="GWM">GWM</option>
                      <option value="Haval">Haval</option>
                      <option value="Honda">Honda</option>
                      <option value="Hummer">Hummer</option>
                      <option value="Hyundai">Hyundai</option>
                      <option value="Infiniti">Infiniti</option>
                      <option value="Isuzu">Isuzu</option>
                      <option value="JAC">JAC</option>
                      <option value="Jaguar">Jaguar</option>
                      <option value="Jeep">Jeep</option>
                      <option value="Jetour">Jetour</option>
                      <option value="JMC">JMC</option>
                      <option value="Kia">Kia</option>
                      <option value="Lamborghini">Lamborghini</option>
                      <option value="Land Rover">Land Rover</option>
                      <option value="Lexus">Lexus</option>
                      <option value="Lincoln">Lincoln</option>
                      <option value="Lotus">Lotus</option>
                      <option value="Maserati">Maserati</option>
                      <option value="Mastretta">Mastretta</option>
                      <option value="Maybach">Maybach</option>
                      <option value="Mazda">Mazda</option>
                      <option value="McLaren">McLaren</option>
                      <option value="Mercedes-Benz">Mercedes-Benz</option>
                      <option value="Mercury">Mercury</option>
                      <option value="MG">MG</option>
                      <option value="Mini">Mini</option>
                      <option value="Mitsubishi">Mitsubishi</option>
                      <option value="Oldsmobile">Oldsmobile</option>
                      <option value="Omoda">Omoda</option>
                      <option value="Peugeot">Peugeot</option>
                      <option value="Plymouth">Plymouth</option>
                      <option value="Pontiac">Pontiac</option>
                      <option value="Porsche">Porsche</option>
                      <option value="RAM">RAM</option>
                      <option value="Renault">Renault</option>
                      <option value="Rover">Rover</option>
                      <option value="SAAB">SAAB</option>
                      <option value="Saturn">Saturn</option>
                      <option value="Scion">Scion</option>
                      <option value="Seat">Seat</option>
                      <option value="Seres">Seres</option>
                      <option value="Smart">Smart</option>
                      <option value="SRT">SRT</option>
                      <option value="SsangYong">SsangYong</option>
                      <option value="Subaru">Subaru</option>
                      <option value="Suzuki">Suzuki</option>
                      <option value="Toyota">Toyota</option>
                      <option value="UAZ">UAZ</option>
                      <option value="VAM">VAM</option>
                      <option value="Volvo">Volvo</option>
                    </select>
                  </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group m-0">
                      <select
                        name="subject"
                        id="subject"
                        className="form-select"
                        defaultValue={"Choose"}
                      >
                        <option value="Choose">Elige el año</option>
                        <option value="Construction">1999</option>
                        <option value="Real Estate">2000</option>
                        <option value="Industry">2001</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <button type="submit" className="btn style2 m-0 w-100">
                    BUSCAR
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xxl-7 col-xl-6">
            <div className="row global-carousel service-client-slider">
              <Swiper
                loop={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                spaceBetween={20}
                slidesPerGroup={1}
                speed={1000}
                pagination={{ clickable: true }}
                className="mySwiper"
                modules={[FreeMode, Navigation, Thumbs, EffectFade, Autoplay]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                  1400: {
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div>
                    <div className="client-logo">
                      <Link to="#">
                        <img src="assets/img/client/Chevrolet.png" alt="Autopartes para Chevrolet" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="client-logo">
                      <Link to="#">
                        <img src="assets/img/client/Ford.png" alt="Autopartes para Ford" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="client-logo">
                      <Link to="#">
                        <img src="assets/img/client/Nissan.png" alt="Autopartes para Nissan" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="client-logo">
                      <Link to="#">
                        <img src="assets/img/client/Renault.png" alt="Autopartes para Renault" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="client-logo">
                      <Link to="#">
                        <img src="assets/img/client/Volkswagen.png" alt="Autopartes para Volkswagen" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="client-logo">
                      <Link to="#">
                        <img src="assets/img/client/Toyota.png" alt="Autopartes para Toyota" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="client-logo">
                      <Link to="#">
                        <img src="assets/img/client/Susuki.png" alt="Autopartes para Susuki" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="client-logo">
                      <Link to="#">
                        <img src="assets/img/client/Honda.png" alt="Autopartes para Honda" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="client-logo">
                      <Link to="#">
                        <img src="assets/img/client/BMW.png" alt="Autopartes para BMW" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAreaThree;
