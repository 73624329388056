import React, { useState } from "react";
import Slider from "rc-slider";
import { Link } from "react-router-dom";


const ShopArea = () => {
  const [range, setRange] = useState([0, 100]);

  const handleRangeChange = (value) => {
    setRange(value);
  };
  return (

    
    <section className="space-extra-bottom">
      <div className="container">

      <div className="row gy-30 space">
          <div className="col-lg-12">
            <div className="team-card">
              
              <div >
                <h4>Todos nuestros pernos están fabricados en acero con bujes de latón en acabado <i>Tropicalizado</i> </h4>
                <img src="assets/img/productos/cotizar-pernos-para-puerta-RALTE-entregas-inmediatas.webp" alt="Cotiza Pernos para Puerta RALTE" />
              </div>
              <div className="team-card_img">
                
              </div>
              
            </div>
          </div>
        </div>

        <div className="row flex-row-reverse">
          <div className="col-xl-9 col-lg-8">
            <div className="shop-sort-bar">
              <div className="row justify-content-between align-items-center">
                <div className="col-md">
                  <p className="woocommerce-result-count">
                    Mostrando 1–15 de 52 resultados
                  </p>
                </div>
                <div className="col-md-auto">
                  <form className="woocommerce-ordering" method="get">
                    <div className="form-group mb-0">
                      <select
                        name="orderby"
                        className="single-select orderby"
                        aria-label="Shop order"
                        defaultValue={"Choose"}
                      >
                        <option value="Choose">Orden por número</option>
                        <option value="popularity">Más Populares</option>
                        <option value="date">Más recientes</option>
                        {/*<option value="rating">Sort by average rating</option> 
                        <option value="price">
                          Sort by price: low to high
                        </option>
                        <option value="price-desc">
                          Sort by price: high to low
                        </option>*/}
                      </select>
                      <i className="fas fa-angle-down" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-xl-4 col-md-6">
                <div className="product-card style2">
                  <div className="product-img">
                    <img  
                      src="assets/img/productos/pernos/1-100-PP1.webp"
                      alt="Fixturbo"
                    />
                    <span className="tag">
                      <span className="product-tag">100PP1</span>
                      <span className="offer-tag">100PP1</span>
                    </span>
                  </div>
                  <div className="product-content">
                    <h3 className="product-title">
                      Perno para Puerta 1 / 100-PP1
                    </h3>
                    <p><b>Largo:</b> 104.7 mm <br/> <b>Diámetro:</b> 11/32 pulg.<br/>
                      <b>Aplicaciones:</b> Universal, Chevrolet, Dina, Dodge, Ford, General Motors.{" "}   
                    </p>
                    {/*<span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </span>
                    <span className="price">
                      <del>$30</del> $25
                    </span>
                    <Link to="#" className="link-btn">
                      Add to cart <i className="fas fa-arrow-right" />
                    </Link>*/}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="product-card style2">
                  <div className="product-img">
                    <img
                      src="assets/img/productos/pernos/1F-100-PP1F.webp"
                      alt="Fixturbo"
                    />
                  </div>
                  <div className="product-content">
                    <h3 className="product-title">
                      <Link to="/shop-details">Perno de Puerta 1F / 100-PP1F</Link>
                    </h3>
                    <p>Fabricado en acero con bujes gruesos de latón de 101.6mm de largo, con diámetro de 11/32 pulg. Acabado tropicalizado{" "}   
                    <b>Aplicaciones:</b> Dina, Dodge, Ford, General Motors</p>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </span>
                    <span className="price">
                      <del>$30</del> $25
                    </span>
                    <Link to="#" className="link-btn">
                      Add to cart <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="product-card style2">
                  <div className="product-img">
                    <img
                      src="assets/img/update-img/product/1-3.png"
                      alt="Fixturbo"
                    />
                  </div>
                  <div className="product-content">
                    <h3 className="product-title">
                      <Link to="/shop-details">Windshield wiper motor</Link>
                    </h3>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </span>
                    <span className="price">
                      <del>$30</del> $25
                    </span>
                    <Link to="#" className="link-btn">
                      Add to cart <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="product-card style2">
                  <div className="product-img">
                    <img
                      src="assets/img/update-img/product/1-4.png"
                      alt="Fixturbo"
                    />
                  </div>
                  <div className="product-content">
                    <h3 className="product-title">
                      <Link to="/shop-details">Power steering pump</Link>
                    </h3>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </span>
                    <span className="price">
                      <del>$30</del> $25
                    </span>
                    <Link to="#" className="link-btn">
                      Add to cart <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="product-card style2">
                  <div className="product-img">
                    <img
                      src="assets/img/update-img/product/1-5.png"
                      alt="Fixturbo"
                    />
                  </div>
                  <div className="product-content">
                    <h3 className="product-title">
                      <Link to="/shop-details">Windshield wiper motor</Link>
                    </h3>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </span>
                    <span className="price">
                      <del>$30</del> $25
                    </span>
                    <Link to="#" className="link-btn">
                      Add to cart <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="product-card style2">
                  <div className="product-img">
                    <img
                      src="assets/img/update-img/product/1-6.png"
                      alt="Fixturbo"
                    />
                  </div>
                  <div className="product-content">
                    <h3 className="product-title">
                      <Link to="/shop-details">Exhaust manifold</Link>
                    </h3>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </span>
                    <span className="price">
                      <del>$30</del> $25
                    </span>
                    <Link to="#" className="link-btn">
                      Add to cart <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="product-card style2">
                  <div className="product-img">
                    <img
                      src="assets/img/update-img/product/1-7.png"
                      alt="Fixturbo"
                    />
                  </div>
                  <div className="product-content">
                    <h3 className="product-title">
                      <Link to="/shop-details">Engine pistons and cog</Link>
                    </h3>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </span>
                    <span className="price">
                      <del>$30</del> $25
                    </span>
                    <Link to="#" className="link-btn">
                      Add to cart <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="product-card style2">
                  <div className="product-img">
                    <img
                      src="assets/img/update-img/product/1-8.png"
                      alt="Fixturbo"
                    />
                  </div>
                  <div className="product-content">
                    <h3 className="product-title">
                      <Link to="/shop-details">Exhaust manifold</Link>
                    </h3>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </span>
                    <span className="price">
                      <del>$30</del> $25
                    </span>
                    <Link to="#" className="link-btn">
                      Add to cart <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="product-card style2">
                  <div className="product-img">
                    <img
                      src="assets/img/update-img/product/1-9.png"
                      alt="Fixturbo"
                    />
                  </div>
                  <div className="product-content">
                    <h3 className="product-title">
                      <Link to="/shop-details">Windshield wiper motor</Link>
                    </h3>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </span>
                    <span className="price">
                      <del>$30</del> $25
                    </span>
                    <Link to="#" className="link-btn">
                      Add to cart <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination justify-content-center mt-70">
              <ul>
                <li>
                  <Link to="/blog">1</Link>
                </li>
                <li>
                  <Link to="/blog">2</Link>
                </li>
                <li>
                  <Link to="/blog">3</Link>
                </li>
                <li>
                  <Link to="/blog">
                    <i className="fas fa-angle-right" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 sidebar-widget-area">
            <aside className="sidebar-sticky-area sidebar-area sidebar-shop">
              <div className="widget widget_search">
                <h3 className="widget_title">Buscar</h3>
                <form className="search-form">
                  <input type="text" placeholder="Encuentra tu Perno" />
                  <button type="submit">
                    <i className="fas fa-search" />
                  </button>
                </form>
              </div>
              <div className="widget widget_categories  ">
                <h3 className="widget_title">Pernos por marca</h3>
                <ul>
                  <li>
                    <Link to="/service-details">Universal</Link>
                    <span>(12)</span>
                  </li>
                  <li>
                    <Link to="/service-details">General Motors</Link>{" "}
                    <span>(12)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Chevrolet</Link>
                    <span>(08)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Ford</Link>
                    <span>(13)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Dina</Link>
                    <span>(03)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Dodge</Link>
                    <span>(03)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Nissan</Link>
                    <span>(03)</span>
                  </li>
                </ul>
              </div>
              <div className="widget widget_categories  ">
                <h3 className="widget_title">Pernos por modelo</h3>
                <ul>
                  <li>
                    <Link to="/service-details">Tsuru I</Link>
                    <span>(12)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Datsun</Link>{" "}
                    <span>(12)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Shadow</Link>
                    <span>(08)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Golf</Link>
                    <span>(13)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Jetta</Link>
                    <span>(03)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Suburban</Link>
                    <span>(03)</span>
                  </li>
                  <li>
                    <Link to="/service-details">Topaz</Link>
                    <span>(03)</span>
                  </li>
                </ul>
              </div>
              {/*<div className="widget widget_price_filter">
                <h4 className="widget_title">Filter By Price</h4>
                <div style={{ width: "220px", margin: "20px" }}>
                  <Slider
                    range
                    min={0}
                    max={600}
                    defaultValue={[0, 100]}
                    value={range}
                    onChange={handleRangeChange}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  ></div>
                </div>
                <div className="price_slider_wrapper">
                  <div className="price_slider" />
                  <div className="price_label">
                    Price: <span className="from">${range[0]}</span> —{" "}
                    <span className="to">${range[1]}</span>
                    <button type="submit" className="button btn">
                      Filter
                    </button>
                  </div>
                </div>
              </div>
              <div className="widget product_ratting_widget">
                <h3 className="widget_title">Sort by Raiting</h3>
                <ul>
                  <li>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star unavailable" />
                    </span>
                    <span>(12)</span>
                  </li>
                  <li>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star unavailable" />
                      <i className="fas fa-star unavailable" />
                    </span>
                    <span>(5)</span>
                  </li>
                  <li>
                    <span className="star-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star unavailable" />
                      <i className="fas fa-star unavailable" />
                      <i className="fas fa-star unavailable" />
                    </span>
                    <span>(3)</span>
                  </li>
                </ul>
              </div>*/}
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopArea;
