import React, { useEffect, useState } from "react";
import HeaderOne from "../components/HeaderOne";
import HeaderTwo from "../components/HeaderTwo";
import HeroOne from "../components/HeroOne";
import CounterOne from "../components/CounterOne";
import CategoryAreaOne from "../components/CategoryAreaOne";
import ShopArea from "../components/ShopArea";
import ClientAreaOne from "../components/ClientAreaOne";
import WhyChooseUsOne from "../components/WhyChooseUsOne";
import FaqAreaThree from "../components/FaqAreaThree";



import FooterAreaOne from "../components/FooterAreaOne";
import SubscribeOne from "../components/SubscribeOne";
import Preloader from "../helper/Preloader";

const HomePageOne = () => {
  let [active, setActive] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 2000);
  }, []);
  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Header one */}
      <HeaderTwo />

      {/* Hero One */}
      <HeroOne />

      {/* Counter One */}
      <CounterOne />

      {/* Shop Area */}
      <ShopArea />

      {/* Client Area One */}
      <ClientAreaOne />

      {/* Why Choose Us One */}
      <WhyChooseUsOne />

      {/* Faq Area three */}
      <FaqAreaThree />
    
      {/* Subscribe One */}
      <SubscribeOne />

      {/* Footer Area One */}
      <FooterAreaOne />
    </>
  );
};

export default HomePageOne;
